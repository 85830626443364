"use client";

import ContentContainer from "@/app/[locale]/(landingPage)/(homepageLayout)/home/components/ContentContainer";
import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "@nextui-org/react";
import Image from "next/image";
import Image33 from "./image 33.png";
import Image34 from "./image 34.png";
import Image35 from "./image 35.png";
import Image36 from "./image 36.png";
import Image37 from "./image 37.png";
import Image38 from "./image 38.png";
import useDisclosure from "@/hooks/shared/useDisclosure";
import ContactFormModal from "../ContactFormModal";

const services = {
  callbot: [
    {
      image: Image33,
      title: "Bất động sản",
      description:
        "Các doanh nghiệp bất động sản có thể tiếp cận khách hàng mới nhờ AI Callbot Auto Call để giới thiệu dự án chung cư, đầu tư đất nền mới.",
    },
    {
      image: Image34,
      title: "Tài chính",
      description:
        "Công ty tài chính dùng dịch vụ auto call AI Callbot để gọi tiếp cận khách hàng có nhu cầu vay vốn. Sau đó, nhân viên gọi lại để tư vấn các gói vay.",
    },
    {
      image: Image35,
      title: "Giáo dục",
      description:
        "Các lớp học, trung tâm giáo dục có thể sử dụng AI Callbot Auto Call để tìm kiếm khách hàng, chăm sóc học viên tự động, giới thiệu khóa học, nhắc lịch hẹn.",
    },
    {
      image: Image36,
      title: "Bảo hiểm",
      description:
        "Công ty bảo hiểm có thể dùng dịch vụ auto call Callbot AI để gọi tìm kiếm khách hàng có nhu cầu mua bảo hiểm. Sau đó, nhân viên gọi lại để sales, tư vấn các gói bảo hiểm.",
    },
    {
      image: Image37,
      title: "Spa Thẩm mỹ",
      description:
        "Chủ spa, thẩm mỹ có thể dùng phần mềm auto call Callbot AI nhắc lịch hẹn, tư vấn gói spa làm đẹp, nâng cao trải nghiệm khách hàng và chất lượng dịch vụ.",
    },
    {
      image: Image38,
      title: "Bán lẻ",
      description:
        "Chủ kinh doanh bán lẻ dùng giải pháp auto call AI Callbot để dễ dàng tìm kiếm khách hàng tiềm năng, gọi chốt đơn tự động, để tăng doanh số bán hàng.",
    },
  ],
  crm: [
    {
      image: Image33,
      title: "Kinh doanh B2B",
      description:
        "Các doanh nghiệp kinh doanh B2B dùng CRM Quản lý bán hàng để quản lý danh sách khách hàng, quy trình xúc tiến án hàng, quản lý đội ngũ sales và chăm sóc khách hàng.",
    },
    {
      image: Image34,
      title: "Bán lẻ - Ecommerce",
      description:
        "Doanh nghiệp bán lẻ hàng hóa và kinh doanh thương mại điện tử dùng CRM Quản lý bán hàng để quản lý danh sách khách hàng, đơn hàng và quản lý hội thoại đa kênh.",
    },
    {
      image: Image35,
      title: "Spa Thẩm mỹ",
      description:
        "Chủ spa, thẩm mỹ có thể dùng CRM quản lý bán hàng để quản lý danh sách khách hàng, nuôi dưỡng khách hàng tự động giúp tăng trải nghiệm khách hàng.",
    },
    {
      image: Image36,
      title: "Kinh doanh phân phối",
      description:
        "Doanh nghiệp kinh doanh phân phối dùng CRM quản lý bán hàng để quản lý thông tin đối tác, đại lý, khách hàng và theo dõi sản phẩm trong kho và theo dõi doanh số.",
    },
    {
      image: Image37,
      title: "Bất động sản",
      description:
        "Các doanh nghiệp bất động sản dùng CRM Quản lý khách hàng để theo dõi hành trình bán hàng và lọc khách hàng bằng giải pháp Callbot AI gọi điện tự động.",
    },
    {
      image: Image38,
      title: "Giáo dục",
      description:
        "Các lớp học, trung tâm giáo dục, trung tâm edtech có thể dùng CRM để quản lý thông tin học viên và chăm sóc học viên tự động giúp tăng trải nghiệm khách hàng.",
    },
  ],
  messages: [
    {
      image: Image33,
      title: "Bán lẻ - Ecommerce",
      description:
        "Doanh nghiệp thương mại điện tử (e-commerce) tối ưu hóa tương tác khách hàng bằng tin nhắn ZNS thông báo về đơn hàng, thông báo về ưu đãi, khuyến mãi, voucher,...",
    },
    {
      image: Image34,
      title: "Spa Thẩm mỹ",
      description:
        "Chủ spa, thẩm mỹ tăng trải nghiệm khách hàng bằng tin nhắn ZNS, SMS Marketing như xác nhận lịch hẹn, thông báo khuyến mãi và voucher,...",
    },
    {
      image: Image35,
      title: "Giáo dục",
      description:
        "Lĩnh vực giáo dục sử dụng ZNS, SMS để tạo trải nghiệm tích hợp cho cho phụ huynh và học sinh bằng tin nhắn thông báo kết quả học tập, gửi tài liệu, khuyến mãi,...",
    },
    {
      image: Image36,
      title: "Nhà hàng F&B",
      description:
        "Nhà hàng, quán ăn ứng dụng tin nhắn tự động ZNS, SMS brandname để thông báo xác nhận đặt bàn, thông báo khuyến mãi và voucher, thông báo tích điểm,...",
    },
    {
      image: Image37,
      title: "Tài chính - Ngân hàng",
      description:
        "Ngân hàng, công ty tài chính dùng ZNS, SMS để gửi những thông báo về mở tài khoản thành công hay thông báo giao dịch, thông báo cập nhật về tài khoản.",
    },
    {
      image: Image38,
      title: "Tổ chức Sự kiện",
      description:
        "Các công ty tổ chức sự kiện dùng chiến dịch tin nhắn tự động để xác nhận đăng ký sự kiện, thông báo lịch trình, checkin vào sự kiện bằng tin nhắn ZNS và SMS Brandname.",
    },
  ],
  ulead: [
    {
      image: Image33,
      title: "Bất động sản",
      description:
        "Các doanh nghiệp bất động sản có thể tìm kiếm hàng ngàn khách hàng tiềm năng trên nhóm Facebook về bất động sản và chuyển đổi Facebook UID sang số điện thoại để gọi tự động bằng Callbot AI.",
    },
    {
      image: Image34,
      title: "Tài chính",
      description:
        "Công ty tài chính tìm kiếm khách hàng tiềm năng trên nhóm Facebook và data chủ doanh nghiệp từ website và chuyển đổi Facebook UID sang số điện thoại để gọi tự động bằng Callbot AI.",
    },
    {
      image: Image35,
      title: "Giáo dục",
      description:
        "Các lớp học, trung tâm giáo dục có thể sử dụng ULead để tìm kiếm data khách hàng tiềm năng là phụ huynh và học sinh từ nhóm Facebook và lọc khách hàng tự động bằng Callbot AI Auto Call.",
    },
    {
      image: Image36,
      title: "Bảo hiểm",
      description:
        "Công ty bảo hiểm tìm kiếm khách hàng tiềm năng trên nhóm Facebook và chuyển đổi Facebook UID sang số điện thoại để gọi tự động bằng Callbot AI.",
    },
    {
      image: Image37,
      title: "Spa Thẩm mỹ",
      description:
        "Chủ spa, thẩm mỹ sử dụng ULead để tìm kiếm data khách hàng tiềm năng là phụ huynh và học sinh từ nhóm Facebook và quản lý khách hàng bằng CRM.",
    },
    {
      image: Image38,
      title: "Bán lẻ",
      description:
        "Chủ kinh doanh bán lẻ dùng giải pháp tìm kiếm khách hàng ULead để tìm kiếm nhanh chóng data khách hàng từ nhóm Facebook và chuyển đổi UID thành số điện thoại.",
    },
  ],
  all: [
    {
      image: Image33,
      title: "Bất động sản",
      description:
        "Tổng đài ảo ứng dụng trong bất động sản để nhân viên telesales gọi điện  cho khách hàng cung cấp thông tin về các dự án, chính sách bán hàng, giá cả và tình trạng pháp lý của bất động sản.",
    },
    {
      image: Image34,
      title: "Tài chính",
      description:
        "Công ty tài chính sử dụng call center để tư vấn các sản phẩm tài chính như thẻ tín dụng, cho vay,... và trả lời nhanh chóng cuộc gọi của khách hàng về số dư tài khoản, biểu phí, lãi suất,...",
    },
    {
      image: Image35,
      title: "Giáo dục",
      description:
        "Các lớp học, trung tâm giáo dục có thể sử dụng tổng đài ảo để nhận cuộc gọi từ phụ huynh và học sinh về các vấn đề trong trường học và gọi điện telesales tư vấn khóa học, chương trình đào tạo.",
    },
    {
      image: Image36,
      title: "Bảo hiểm",
      description:
        "Công ty bảo hiểm sử dụng tổng đài ảo để tiếp nhận cuộc gọi hỏi về điều khoản, quyền lợi, và quy trình yêu cầu bồi thường và gọi điện telesales tư vấn bảo hiểm sức khỏe, bảo hiểm nhân thọ,...",
    },
    {
      image: Image37,
      title: "Spa Thẩm mỹ",
      description:
        "Chủ spa, thẩm mỹ sử dụng tổng đài ảo để gọi khách hàng thông báo nhắc lịch hẹn và khách hàng gọi vào tổng đài để đặt lịch hẹn, yêu cầu tư vấn đề dịch vụ, liệu trình thẩm mỹ,...",
    },
    {
      image: Image38,
      title: "Bán lẻ",
      description:
        "Chủ kinh doanh bán lẻ sử dụng tổng đài ảo để nhận cuộc gọi từ khách hàng liên quan đến chính sách đổi trả, bảo hành, và hỗ trợ kỹ thuật và gọi điện cho khách hàng tư vấn về sản phẩm, khuyến mãi,...",
    },
  ],
  telesales: [
    {
      image: Image33,
      title: "Bất động sản",
      description:
        "Doanh nghiệp bất động sản sử dụng tổng đài ảo telesales để quay số gọi điện, sàng lọc khách tiềm năng, nâng cao tỷ lệ chốt giao dịch, tiết kiệm chi phí vận hành, tăng nhận diện thương hiệu.",
    },
    {
      image: Image34,
      title: "Tài chính",
      description:
        "Công ty tài chính sử dụng tổng đài ảo telesales để tư vấn sản phẩm đầu tư, bảo hiểm, trái phiếu, bất động sản, tối ưu chi phí vận hành, tăng tỷ lệ chuyển đổi, nâng cao trải nghiệm khách hàng.",
    },
    {
      image: Image35,
      title: "Giáo dục",
      description:
        "Trường học, trung tâm dạy học, tư vấn du học sử dụng tổng đài ảo telesales để tư vấn chương trình đào tạo và du học, bất động sản, tối ưu chi phí, nâng cao dịch vụ, tăng tỷ lệ chuyển đổi.",
    },
    {
      image: Image36,
      title: "Bảo hiểm",
      description:
        "Công ty bảo hiểm sử dụng tổng đài ảo telesales để quay số tự động gọi điện tư vấn gói bảo hiểm, tối ưu chi phí, tăng tỷ lệ chuyển đổi, nâng cao chất lượng dịch vụ, củng cố lòng tin khách hàng.",
    },
    {
      image: Image37,
      title: "Spa Thẩm mỹ",
      description:
        "Các spa thẩm mỹ, trung tâm thể hình sử dụng tổng đài ảo telesales để tư vấn dịch vụ làm đẹp, fitness, tối ưu vận hành, nâng cao trải nghiệm khách hàng, tiết kiệm chi phí, tăng tỷ lệ chốt dịch vụ.",
    },
    {
      image: Image38,
      title: "Bán lẻ",
      description:
        "Chuỗi bán lẻ sử dụng tổng đài ảo telesales để gọi điện quay số tự động tư vấn sản phẩm, cung cấp thông tin khuyến mãi, tối ưu chi phí, nâng cao trải nghiệm khách hàng, tăng tỷ lệ chuyển đổi.",
    },
  ],
};

const UseCasesGrid = ({ type }: { type: string }) => {
  const { open, isOpen, close } = useDisclosure();
  return (
    <div className="w-full min-h-[680px] bg-adahubSecondary">
      <ContentContainer>
        <div className="pb-10">
          <div className="text-center text-white mt-10">
            <div className="text-lg mb-6 hidden md:block ">
              Công cụ tối ưu cho đa ngành nghề và mục đích
            </div>
            <h3 className="font-semibold text-2xl text-white">
              {type === "callbot" && (
                <>
                  UCALL được thiết kế với khả năng tuỳ biến cao đáp ứng nhu cầu
                  quản lý bán hàng cho mọi lĩnh vực kinh doanh, mọi uy mô doanh
                  nghiệp
                </>
              )}
              {type === "crm" && (
                <>
                  Adahub được thiết kế với khả năng tuỳ biến cao đáp ứng nhu cầu
                  quản lý bán hàng cho mọi lĩnh vực kinh doanh, mọi uy mô doanh
                  nghiệp{" "}
                </>
              )}
              {type === "messages" && (
                <>
                  UCALL được thiết kế với khả năng tuỳ biến cao đáp ứng nhu cầu
                  quản lý bán hàng cho mọi lĩnh vực kinh doanh, mọi uy mô doanh
                  nghiệp{" "}
                </>
              )}
              {type === "ulead" && (
                <>
                  UCALL được thiết kế với khả năng tuỳ biến cao đáp ứng nhu cầu
                  quản lý bán hàng cho mọi lĩnh vực kinh doanh, mọi uy mô doanh
                  nghiệp{" "}
                </>
              )}
              {type === "all" && (
                <>
                  UCALL được thiết kế với khả năng tuỳ biến cao đáp ứng nhu cầu
                  quản lý bán hàng cho mọi lĩnh vực kinh doanh, mọi uy mô doanh
                  nghiệp{" "}
                </>
              )}
              {type === "telesales" && (
                <>
                  Adahub được thiết kế với khả năng tuỳ biến cao đáp ứng nhu cầu
                  quản lý bán hàng cho mọi lĩnh vực kinh doanh, mọi uy mô doanh
                  nghiệp
                </>
              )}
            </h3>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-10 mt-8">
            {services[type]?.map(({ image, title, description }, index) => (
              <Card
                key={index}
                isFooterBlurred
                radius="lg"
                className="border-none">
                <CardHeader className="flex justify-center">
                  <Image
                    alt={title}
                    className="object-cover"
                    height={120}
                    src={image}
                    placeholder="blur"
                    width={120}
                  />
                </CardHeader>
                <CardBody className="flex flex-col gap-3 text-center">
                  <h1 className="text-xl text-adahubSecondary font-semibold">
                    {title}
                  </h1>
                  <p>{description}</p>
                </CardBody>
                <CardFooter className="flex justify-center">
                  <Button
                    onClick={() => {
                      open();
                    }}
                    className="bg-adahubSecondary text-white"
                    color="primary">
                    Tìm hiểu thêm
                  </Button>
                </CardFooter>
              </Card>
            ))}
          </div>
          <ContactFormModal isOpen={isOpen} open={open} close={close} />
        </div>
      </ContentContainer>
    </div>
  );
};

export default UseCasesGrid;
